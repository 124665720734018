/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  height: 100vh;
}

img {
  width: 100%;
  height: auto;
}

:root {
  --smd-dark-color: rgb(83, 83, 83);
  --smd-seal-color: #4ca9ac;
  --smd-blue-color: #615BBE;
  --smd-light-blue-color: #BFBBFF;
  --smd-green-color: #06ffa1;
  --smd-orange-color: #FF9800;

  --smd-light-background: #F6F5FE;
  --smd-shadow-color: #A8AAAD;
  --smd-border-color: #DDDDDD;

  --smd-text-color: #363458;
  --smd-light-text-color: #9E9FBA;

  --smd-priority-green: #3EAF3F;
  --smd-priority-orange: #FF9800;
  --smd-priority-red: #FF3D3D;

  --smd-pastel-purple: #615BBE;
  --smd-pastel-red: #B12A6D;
  --smd-pastel-orange: #FFA36E;
  --smd-pastel-green: #4CA9AC;
  --smd-pastel-violet: #AA5BBE;

  font-family: Montserrat, sans serif;
}

p,
h1,
h2,
h3,
h4,
span,
input {
  color: var(--smd-text-color);
  font-family: Montserrat, sans serif;
}

.padding-30 {
  padding: 30px !important;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
  margin: 0;
}

h1 {
  font-size: xx-large;
}

h2 {
  font-size: x-large;
}

h3 {
  font-size: large;
}

h4 {
  font-size: medium;
}

span {
  font-size: small;
}

/* Hidding arrow up/down buttons into input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hidding arrow up/down buttons into input number */
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mat-dialog-container {
  border-radius: 20px !important;
}

.divider-horizontal {
  height: 1px;
  margin: 20px 0;
  background-color: var(--smd-border-color);
}

.divider-vertical {
  width: 1px;
  margin: 0 20px;
  background-color: var(--smd-border-color);
}

.spacer-10 {
  height: 10px;
}
